<template>
  <registration-code-edit-create :organization-id="organization.id" />
</template>

<script>
import Vue from 'vue';
import RegistrationCodeEditCreate from '~/pages/shared/registration-codes/partials/EditCreate.vue';

export default Vue.extend({

  components: {
    RegistrationCodeEditCreate,
  },

  middleware: 'tenantmember',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
});
</script>
